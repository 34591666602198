import React, {useEffect, useState} from 'react';
import axios, {AxiosError} from "axios";
import {Loader} from "~/Components/base/Loader";

import {is_prod} from "~/Components/Function/isProd";
import {afishaProp} from "~/Components/Afisha/AfishaScreenProp";
import {ImageSizesProp} from "~/Props/ImageSizesProp";
import {Hero} from "~/Components/base/Hero";
import {HeroProps} from "~/Props/HeroProp";
import {AfishaScreen} from "~/Components/Afisha/AfishaScreen";
import {AfishaSlider} from "~/Components/Afisha/AfishaSlider";

interface AfishaPageProp {
    hero: HeroProps,
    list: [
        {
            img: ImageSizesProp,
            title: string,
            desc: string,
            id: string
        }
    ],
    desc?: string
    afisha: afishaProp

}

export const Afisha = () => {
    const [data, setData] = useState<AfishaPageProp>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    async function fetchData() {
        try {
            setError('');
            setLoading(true);
            const url = is_prod ? '/wp-admin/admin-ajax.php' : '/json/afisha.json';

            let form_data = new FormData;
            if (is_prod) {
                form_data.append('action', 'get_afisha_page');
                form_data.append('url', window.location.href);
            }

            const response = await axios.post<AfishaPageProp>(url, form_data);

            setData(response.data);
            setLoading(false);
        } catch (e: unknown) {
            const error = e as AxiosError;
            setLoading(false);
            setError(error.message);
        }
    }

    useEffect(() => {
        fetchData();
    }, [])


    return (
        <div className="afisha-page">
            <Loader loading={loading}/>
            {error && <p>{error}</p>}
            {(!loading && error == '' && data) &&
				<>
					<Hero data={data.hero}/>
					<AfishaScreen data={data.afisha} full={false}/>
                    {data?.list.length > 0 && false && <AfishaSlider items={data.list} desc={data.desc}/>}
				</>

            }
        </div>
    )
}