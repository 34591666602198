import {Link} from "react-router-dom";
import {useState} from "react";
import {is_prod} from "~/Components/Function/isProd";
import {VerandaProp} from "~/Pages/Veranda/VerandaProp";
import {ModalBtn} from "~/Components/Modal/ModalBtn";
import {ModalCallback} from "~/Components/Modal/ModalCallback";

export const Header = () => {
    const [openMenu, setOpenMenu] = useState<boolean>(false);
    const [modalState, setModalState] = useState(false)

    const linkClick = () => {
        setOpenMenu(false);
    }


    return (
        <header className={openMenu ? "header header--open-menu" : "header"}>
            <div className="header-btn" onClick={() => setOpenMenu((val) => !val)}>
                <div className={"gamburger" + (openMenu ? " active" : "")}></div>
            </div>
            <div className="header-menu">
                <div className="noise"></div>
                <div className="content">
                    <div className="header-menu-col">
                        <ul>
                            <li><Link to="/" onClick={linkClick}>Главная</Link></li>
                            <li><Link to="/afisha" onClick={linkClick}>Афиша</Link></li>
                            <li><Link to="/restaurant" onClick={linkClick}>Ресторан</Link></li>
                            <li><Link to="/bathhouse" onClick={linkClick}>Баня и хамам</Link></li>
                            <li><Link to="/hotel" onClick={linkClick}>Гостиница</Link></li>
                        </ul>
                    </div>
                    <div className="header-menu-col">
                        <ul>
                            <li><Link to="/catering" onClick={linkClick}>Кейтеринг</Link></li>
                            <li><Link to="/guest" onClick={linkClick}>Гостевые дома</Link></li>
                            <li><Link to="/veranda" onClick={linkClick}>Летние веранды</Link></li>
                            {/*
                            <li><Link to="/tourist" onClick={linkClick}>Туристический портал</Link></li>
*/}
                            <li><Link to="/about-us" onClick={linkClick}>Контакты</Link></li>
                        </ul>

                        <div className="header-menu-col__bot">
                            <a href="tel:+78002227485" target="_blank" rel="noreferrer" className="h3">+7 (800)
                                222-74-85
                            </a>
                            <br/>
                            <p className="h3">г. Муром, с. Ковардицы, ул. Сосновый бор, 1а</p>
                        </div>
                    </div>
                </div>
            </div>

            <ModalBtn state={modalState} setState={setModalState} className="btn header-fixed-btn">Связаться с
                нами</ModalBtn>
            <div className="header-callback-modal">
                <ModalCallback setState={setModalState} state={modalState}/>
            </div>

        </header>
    )
}